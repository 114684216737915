@font-face {
  font-family: 'Satoshi-Variable';
  src: url('./assets/fonts/satoshi/Satoshi-Variable.woff2') format('woff2'),
       url('./assets/fonts/satoshi/Satoshi-Variable.woff') format('woff'),
       url('./assets/fonts/satoshi/Satoshi-Variable.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: normal;
}

/* to add smooth scroll when scrolling from the table of contents */
html{
  scroll-padding-top: 104px; 
  scroll-behavior: smooth
}

body {
  margin: 0;
  font-family: "Satoshi-Variable",  sans-serif;
  font-weight: 500 !important;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss03' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}